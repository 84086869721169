import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class Admin extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { user } = this.props;
      if (!_.isEmpty(user)) {
        if (!user.is_admin) {
          this.context.router.history.push('/dashboard');
        }
      }
    }

    componentWillUpdate(nextProps) {
      const { user } = nextProps;
      if (!_.isEmpty(user)) {
        if (!user.is_admin) {
          this.context.router.history.push('/dashboard');
        }
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  Admin.propTypes = {
    user: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }

  return connect(mapStateToProps)(Admin);
}
