import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAppointment } from '../../actions/sessions';
import { ENVIRONMENT } from '../../environment';

class Redirect extends Component {
  constructor(props) {
    super(props);
    this.state = { dynamic_link: '' };
  }

  componentDidMount() {
    const id = this.props.match.params.appointment_id;

    localStorage.clear();
    localStorage.setItem('token', this.props.match.params.token);

    this.props.fetchAppointment(id, response => {
      // dynamic links wont work for local
      if (ENVIRONMENT === 'DEVELOPMENT') {
        return window.location.replace(
          `http://localhost:8080/sessions/clients/video_call/${id}`,
        );
      }

      this.setState(() => ({
        dynamic_link: response.data.dynamic_link,
      }));

    });
    setTimeout(() => {
      return window.location.replace(this.state.dynamic_link);
    }, 2500);
  }

  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        
          <p style={{ fontSize: '40px' }}>
            click {this.state.dynamic_link ? <a href={this.state.dynamic_link}>here</a> : 'here'} if you are not
            automatically directed in 5 seconds...
          </p>
        
      </div>
    );
  }
}

const mapStateToProps = () => ({});

Redirect.propTypes = {
  match: PropTypes.object.isRequired,
  fetchAppointment: PropTypes.func.isRequired,
};

Redirect.defaultProps = {};

export default connect(mapStateToProps, { fetchAppointment })(Redirect);
