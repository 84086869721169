import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmUser } from '../../actions/auths';
import { fetchUser } from '../../actions/user';

class Confirmation extends Component {
  componentDidMount() {
    const url = this.props.location.pathname;
    const token = url.substring(url.lastIndexOf('/') + 1);
    this.props.confirmUser({ confirmation_token: token }, () => {
      this.props.fetchUser(() => {
        this.props.history.push('/dashboard');
      });
    });
  }

  render() {
    return <div />;
  }
}

Confirmation.propTypes = {
  confirmUser: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

Confirmation.defaultProps = {
  location: {},
};

export default connect(
  null,
  { confirmUser, fetchUser },
)(Confirmation);
