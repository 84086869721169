import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Confirmation = ({ children }) => (
  <div className="signin-container">
    <div className="auth-form">
      {children}
      <Link to="/">
        <p style={{ marginTop: '40px', textAlign: 'center', color: '#6083d0' }}>
          <strong>SIGN IN</strong>
        </p>
      </Link>
    </div>
  </div>
);

Confirmation.propTypes = {
  children: PropTypes.node.isRequired,
};

class ForgotPasswordConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { history } = this.props;
    if (history.location.state.email) {
      return (
        <Confirmation>
          <h3>
            If an account exists with <b>{history.location.state.email}</b>,
            we will send password reset instructions to you shortly.
          </h3>
        </Confirmation>
      );
    }
    return (
      <Confirmation>
        <h3>Sorry, Page has expired... </h3>
      </Confirmation>
    );
  }
}

ForgotPasswordConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ForgotPasswordConfirmation;
