import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { signoutUser } from '../../actions/auths';
import GlobalDialog from '../global/Dialog';

class Signout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [
        <FlatButton label="Cancel" onClick={this.props.toggleModal} />,
        <FlatButton
          label="Logout"
          primary
          style={{ marginLeft: 10 }}
          onClick={this.props.signoutUser}
          className="big-flat-button-green"
        />,
      ],
    };
  }

  render() {
    const { open } = this.props;
    return (
      <GlobalDialog title="Logout" actions={this.state.actions} open={open}>
        Are you sure you wish to log out?
      </GlobalDialog>
    );
  }
}

Signout.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  signoutUser: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default connect(
  null,
  { signoutUser },
)(Signout);
