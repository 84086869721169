import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { signinZendeskUser } from '../../actions/auths';
import ModuleLoadingSpinner from '../global/ModuleLoadingSpinner';

class ZendeskRedirect extends Component {
  componentDidMount() {
    let returnTo;

    if (_.get(this.props.location.state, 'prevUrl')) {
      const url = _.get(this.props.location.state, 'prevUrl');
      const index = url.indexOf('return_to=');
      returnTo = url.slice(index + 'return_to='.length);
    }
    // we remove the timestamp appended by recent zendesk api updates
    const returnToWithoutTimeStamp = returnTo
      ? returnTo.split('&')[0]
      : returnTo;

    this.props.signinZendeskUser(
      { return_to: returnToWithoutTimeStamp },
      response => {
        window.location.href = response.data.zendesk_sso_url;
      },
    );
  }

  render() {
    return (
      <div
        style={{
          height: '500px',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2>
            {this.props.intl.formatMessage({
              defaultMessage:
                'We are now redirecting you to the Knowledge Base',
            })}
          </h2>
          <div style={{ marginLeft: '25px' }}>
            <ModuleLoadingSpinner />
          </div>
        </div>
      </div>
    );
  }
}

ZendeskRedirect.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object,
  signinZendeskUser: PropTypes.func.isRequired,
};

ZendeskRedirect.defaultProps = {};

function mapStateToProps() {
  return {};
}

export default injectIntl(
  connect(mapStateToProps, { signinZendeskUser })(ZendeskRedirect),
);
