import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import PasswordField from 'material-ui-password-field';
import { FormattedMessage, injectIntl } from 'react-intl';
import { resetPassword } from '../../actions/auths';
import { fetchUser } from '../../actions/user';

const renderPasswordField = ({
  label,
  input: { name, value, ...eventHandlers },
  meta: { touched, error },
}) => (
  <div className="input-row" style={{ marginTop: 20 }}>
    <PasswordField
      floatingLabelFixed
      floatingLabelText={label}
      errorText={touched && error && error}
      name={name}
      value={value}
      /* eslint-disable react/jsx-props-no-spreading */
      {...eventHandlers}
    />
  </div>
);

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.fetchUser(() => {
        this.props.history.push('/dashboard');
      });
    }
  }

  onSubmit = values => {
    this.setState({ errorMessage: false });

    this.props.resetPassword(
      this.props.match.params.token,
      values,
      (_response, error) => {
        if (error) {
          let msg = 'Error!';
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            msg = error.response.data.message;
          }
          this.setState({ errorMessage: msg });
        }
      },
    );
  };

  render() {
    const { errorMessage } = this.state;
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className="signin-container">
        <div className="row">
          <div className="col">
            <div className="auth-form">
              <FormattedMessage tagName="h4" defaultMessage="Reset Password" />
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div>
                  <Field
                    name="password"
                    label={formatMessage({ defaultMessage: 'New Password' })}
                    component={renderPasswordField}
                  />
                  <Field
                    name="password_confirmation"
                    label={formatMessage({
                      defaultMessage: 'Password Confirmation',
                    })}
                    component={renderPasswordField}
                  />
                </div>
                <FlatButton
                  style={{ marginTop: '20px' }}
                  className="flat-button-green"
                  primary
                  type="submit"
                  disabled={pristine || submitting || invalid}
                  label={formatMessage({ defaultMessage: 'Reset Password' })}
                />
              </form>
              {errorMessage && (
                <p style={{ color: '#f44336', marginTop: 20 }}>
                  <b>{errorMessage}</b>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  authenticated: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ResetPassword.defaultProps = {
  authenticated: false,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

function validate(values) {
  const errors = {};

  if (!values.password) {
    errors.password = <FormattedMessage defaultMessage="Required" />;
  } else if (values.password.length < 8) {
    errors.password = (
      <FormattedMessage defaultMessage="Password must be at least 8 characters" />
    );
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = (
      <FormattedMessage defaultMessage="Required" />
    );
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = (
      <FormattedMessage defaultMessage="Passwords do not match" />
    );
  }

  return errors;
}

export default injectIntl(
  reduxForm({
    validate,
    form: 'ResetPasswordForm',
  })(connect(mapStateToProps, { fetchUser, resetPassword })(ResetPassword)),
);
