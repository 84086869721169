import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class ConfirmationEmail extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    const { history } = this.props;

    return (
      <div className="signin-container">
        <div className="auth-form">
          <h4>Confirm your account</h4>
          <p>
            Already confirmed?{' '}
            <Link to="/signin">
              <span style={{ color: '#6083d0' }}>
                <strong>Sign in</strong>
              </span>
            </Link>
          </p>
          <br />
          <div className="row center-items">
            {history.location.state ? (
              <p>
                We have sent <span style={{ color: '#777' }}>{history.location.state.email}</span> a
                confirmation, please check your email and follow the link provided
              </p>
            ) : (
              <p>
                We have sent you a confirmation, please check your email and follow the link
                provided
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ConfirmationEmail.propTypes = {
  authenticated: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

ConfirmationEmail.defaultProps = {
  authenticated: false,
};

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
});

export default connect(
  mapStateToProps,
  {},
)(ConfirmationEmail);
