import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Redirect extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    }
    if (this.props.match.params.token) {
      localStorage.setItem('token', this.props.match.params.token);
      window.location.reload();
    }
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
});

Redirect.propTypes = {
  authenticated: PropTypes.bool,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

Redirect.defaultProps = {
  authenticated: false,
};

export default connect(
  mapStateToProps,
  {},
)(Redirect);