import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { TextField } from 'redux-form-material-ui';
import { email as emailValidation } from '../global/validation';
import { forgotPassword } from '../../actions/auths';

const propTypes = {
  email: PropTypes.string,
  forgotPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {
  email: '',
  resetPasswordError: '',
};

const inputStyles = { margin: '8px' };

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      sent: false,
      retry: false,
      second: 0,
      success: '',
    };

    this.timer = null;
  }

  componentDidMount() {
    localStorage.removeItem('token');
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  onSubmit = values => {
    const { second } = this.state;

    if (second === 0) {
      this.setState({ loading: true, sent: false });
      this.props.forgotPassword(values, response => {
        this.setState(
          {
            loading: false,
            sent: true,
            second: 60,
            retry: false,
            success: response,
          },
          () => this.setTimer(),
        );
      });
    } else {
      this.setState({ loading: false, retry: true });
    }
  };

  setTimer = () => {
    const { second } = this.state;

    if (second > 0) {
      this.setState({ second: second - 1 });
      this.timer = setTimeout(() => this.setTimer(), 1000);
    }
  };

  handleChange = () => {
    this.setState({ sent: false });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      resetPasswordError,
    } = this.props;
    const { loading, sent, second, success } = this.state;

    return (
      <div className="signin-container">
        <div className="row">
          <div className="col">
            <div className="auth-form">
              <h4>
                <FormattedMessage defaultMessage="Forgot Password" />
              </h4>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  style={inputStyles}
                  floatingLabelText={
                    <FormattedMessage defaultMessage="Email" />
                  }
                  floatingLabelFixed
                  name="email"
                  disabled={loading || second > 0}
                  onChange={this.handleChange}
                  component={TextField}
                  validate={[emailValidation]}
                />
                <div />
                <FlatButton
                  className="flat-button-green"
                  primary
                  type="submit"
                  disabled={
                    invalid || pristine || submitting || loading || sent
                  }
                  label={
                    <FormattedMessage defaultMessage="Send Password Reset" />
                  }
                />
                <div className="forget-pw-message">
                  {resetPasswordError && (
                    <div className="server-error">{resetPasswordError}</div>
                  )}
                  {loading && (
                    <FormattedMessage
                      className="p"
                      defaultMessage="Sending..."
                    />
                  )}
                  {success && <p>{success}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = propTypes;
ForgotPassword.defaultProps = defaultProps;

function mapStateToProps(state) {
  const selector = formValueSelector('ForgotPasswordForm');

  return {
    email: selector(state, 'email'),
    resetPasswordError: state.auth.resetPasswordError,
  };
}

export default reduxForm({
  form: 'ForgotPasswordForm',
})(connect(mapStateToProps, { forgotPassword })(ForgotPassword));
